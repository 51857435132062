@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
    --primary-light: 252, 173, 16;
    --primary-main: 248, 84, 14;
    --primary-dark: 197, 68, 13;

    --secondary-light: 71, 88, 124;
    --secondary-main: 26, 52, 104;
    --secondary-dark: 7, 27, 67;

    --gray-sdr: #a0aec0;
    --gray-dr: 225, 226, 225;
    --gray-lt: #F5F5F6;

    --white: #fff;

    --text-color: #fff;
    --text-color-wt: #1E1927;

    --red-color: 196, 80, 96;
    --green-color: 0, 179, 138;
    --blue-color: #0086FF;

    --max-width: 1440px;

    --mb-box-bg-color: rgba(255, 255, 255, 0.4);

    --main-bg-color: #040F25;

    --main-font: 'Roboto', -apple-system, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
    --secondary-font: 'Play', -apple-system, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}

/* mobile - 480px */
/* tablet - 1040px */

html,
body {
    font-family: var(--main-font);
    min-height: 100vh;
    background-color: var(--main-bg-color);
    color: var(--text-color);
    overflow: var(--body-overflow, visible);

    .react-toast-notifications__container {
        top: 4rem;
    }
}

body {
    background-image: url('/background.svg');
    background-repeat: no-repeat;
    background-position: var(--body-background-position, center -40px);
    background-size: 100%;

    @media (max-width: 480px) {
        background-position: center 20px;
        background-size: 100%;
    }
}

* {
    box-sizing: border-box;
}

button, a {
    &:focus, &:active {
        outline: none;
    }
}

#__next {
    @media (max-width: 480px) {
        padding-bottom: var(--n-padding-b, 0);
    }
}

body {
    .tippy-box {
        background-color: var(--text-color-wt);
        filter: drop-shadow(0px 0px 2px #fff);
    }

    .tippy-arrow {
        color: var(--text-color-wt);
    }

    .tippy-content {
        padding: 12px 16px;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-in-center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.inl-flex-center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.scroll-top {
    scroll-margin-top: 64px;
}

.link {
    color: var(--text-color);
    transition: border 0.2s ease-in;
    border-bottom: 1px solid rgba(var(--primary-light), 0.4);

    &:hover, &:focus {
        color: rgba(var(--primary-light), 1);
        border-color: rgba(var(--primary-light), 0.9);
    }
}

.my-shadow {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.global-button:not(:disabled) {
    position: relative;

    &:hover {
        &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: inherit;
            background-color: var(--global-button-hover-bg, rgba(255, 255, 255, 0.1));
        }
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 4px rgba(var(--primary-dark), 0.5);
    }

    &:active {
        background-color: var(--global-button-active-bg, rgba(var(--primary-dark), 1));
    }
}

.global-input {
    &:focus {
        outline: none;
        box-shadow: 0 0 0 4px rgba(var(--primary-dark), 0.5);
    }
}
