.swiper-container {
    padding-bottom: 24px;

    .swiper-pagination {
        bottom: 0;
    }

    .swiper-pagination-bullet {
        opacity: 0.5;
        background-color: rgb(var(--primary-light)); 
    }

    .swiper-pagination-bullet-active {
        opacity: 0.75;
    }

    .swiper-slide-next, 
    .swiper-slide-prev {
        opacity: 0.25; 
        transition: opacity 300ms ease;
    }

    .swiper-slide {
        align-self: center;
    }

    .swiper-button-prev, .swiper-button-next {
        color: var(--text-color-wt);
        width: 2.75rem;
        background-color: #FFEECD;
        border: 1px solid #FCAD10;
        border-radius: 8px;
        top: 40%;

        &.button-ds {
            opacity: 0.2;
        }

        &:after {
            font-size: 20px;
            font-weight: bold;
        }
    }
}
