.block {
  position: relative;
  border-radius: 8px;

  &:hover {
    background-color: rgba(var(--secondary-light), 0.5);
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.menuActive {
  border-radius: 10px;
  background-color: rgb(var(--secondary-light));
  cursor: default;
}

.topUp {
  position: absolute;
  bottom: calc(100% - 0.25rem);
  left: 0.25rem;
  font-size: 10px;
  line-height: 1;
  font-weight: bold;
  padding: 2px 20px 6px;
  background-color: rgba(var(--red-color), 0.8);
  text-transform: uppercase;
  clip-path: polygon(50% 100%, 100% 0, 0 0);
  white-space: nowrap;

  @media (max-width: 430px) {
    background-color: rgba(var(--red-color), 0.6);
    bottom: calc(100% - 14px);
    left: -0.5rem;
  }
}
