.topLine {
    top: 0;
    left: 50%;
    z-index: 30;
    height: 0.5rem;
    width: 37.5rem;
    transform: translateX(-50%);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    background-color: #D2B48C;
}

.screw {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%) scale(0.6);

    @media (max-width: 480px) {
        transform: translate(-50%, 50%) scale(0.4);  
    }
}

.block {
    top: 0.25rem;
    left: calc(50% - 16.75rem);
    padding-top: 20vh;
    animation: swinging 6s ease-in-out;
    transform-origin: 50% 0.5rem;

    @media (max-width: 480px) {
        left: calc(50% - 9rem);
    }

    &.center {
        left: calc(50% - 3.25rem);
        animation: swingingCenter 6s ease-in-out;

        @media (max-width: 480px) {
            left: calc(50% - 2.25rem);
        }
    }

    &.right {
        left: calc(50% + 10rem);
        animation: swingingRight 6s ease-in-out;

        @media (max-width: 480px) {
            left: calc(50% + 4.5rem);
        }
    }
}

.line {
    top: 0;
    right: 1.25rem;
    z-index: 10;
    height: calc(20vh + 2.25rem);
    width: 1px; 
    background-color: var(--text-color);

    .center & {
        right: 3rem;
        height: calc(20vh + 1.75rem);

        @media (max-width: 480px) {
            right: 2.25rem;
            height: calc(20vh + 1.25rem);
        }
    }

    .right & {
        right: 3rem; 

        @media (max-width: 480px) {
            right: 1.75rem;
        }
    }
}

.letter {
    font-weight: bold;
    font-size: 11.25rem;
    line-height: 1;
    transform: rotate(12deg);
    color: rgba(var(--primary-main), 0.9);

    @media (max-width: 480px) {
        font-size: 7.5rem;
    }

    .center & {
        transform: rotate(0);
        color: rgba(var(--secondary-main), 0.9);
    }

    .right & {
        transform: rotate(-12deg);
    }
}

.info {
    padding-top: calc(20vh + 14rem);

    @media (max-width: 480px) {
        padding-top: calc(20vh + 12rem);
    }
}

@keyframes swinging {
    0% { transform: rotate(10deg); }
    18% { transform: rotate(-10deg); }
    34% { transform: rotate(7deg); }
    48% { transform: rotate(-5deg); }
    60% { transform: rotate(4deg); }
    70% { transform: rotate(-3deg); }
    78% { transform: rotate(2deg); }
    84% { transform: rotate(-1deg); }
    88% { transform: rotate(1deg); }
    100% { transform: rotate(0deg); } 
}

@keyframes swingingRight {
    0% { transform: rotate(-8deg); }
    18% { transform: rotate(8deg); }
    34% { transform: rotate(-6deg); }
    48% { transform: rotate(5deg); }
    60% { transform: rotate(-4deg); }
    70% { transform: rotate(3deg); }
    78% { transform: rotate(-2deg); }
    84% { transform: rotate(1deg); }
    88% { transform: rotate(-1deg); }
    100% { transform: rotate(0deg); }  
}

@keyframes swingingCenter {
    0% {
        transform: rotate(8deg); 
    }
    25% {
        transform: rotate(-8deg);
    }
    50% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}