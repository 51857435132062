.container {
    justify-content: center;
    background-color: rgb(var(--secondary-dark));
    position: sticky;
    top: 0;
    padding: 0.5rem;
    z-index: 105;

    @media (max-width: 480px) {
        position: fixed;
        width: 100%;
        left: 0;
        top: auto;
        bottom: 0;
        height: 68px;
        border-top: 1px solid rgb(var(--secondary-light));

        nav {
            width: 100%;
            justify-content: space-around;
        }
    }

    nav {
        gap: 0.5rem;
    }

    @media (min-width: 1025px) {
        display: none;
    }
}

.item {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    justify-content: flex-start;
    border-radius: 1.25rem;
    overflow: hidden;
    background: rgb(var(--secondary-light));

    @media (max-width: 480px) {
        transition: background-color 0.25s linear;
    }

    &:hover {
        text-decoration: none;
        background: rgba(var(--primary-dark), 0.4);
    }

    &.active {
        pointer-events: none;
        text-decoration: none;
        background-color: rgba(var(--primary-dark), 1);
    }
}

.text {
    @media (max-width: 480px) {
        display: none;
    }
}

.short {
    font-size: 12px;

    @media (min-width: 481px) {
        display: none;
    } 
}
