.snackbar {
    left: 0;
    bottom: 0;
    z-index: 200;
    background-color: rgba(29, 29, 27, 0.8);
    color: var(--text-color);
    animation: CookieWarning__cookie 0.4s;
}

.close {
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    cursor: pointer;
    fill: var(--white);
}

@keyframes CookieWarning__cookie {
    0% {
        transform: translate3d(0, 100%, 0)
    }

    50% {
        transform: translateZ(0)
    }

    to {
        transform: translateZ(0)
    }
}
