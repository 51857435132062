.header {
    position: relative;
    background-color: rgb(var(--secondary-dark));

    @media (min-width: 1025px) {
        position: sticky;
        top: 0;
        z-index: 100;
    }
}

.inner {
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 480px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.logo {
    display: block;
    margin-top: 0.1rem;
    line-height: 1.8;
    font-size: 1.25rem;
    color: #F8EBFF;
    white-space: nowrap;
    padding: 0.1rem 0.5rem;
    align-self: flex-start;
    text-decoration: none;
    background: url(/logo.png) no-repeat;
    background-size: contain;
    background-position: center;
    text-indent: -9999px;
    width: 140px;

    @media (max-width: 480px) {
        padding-right: 0.5rem;
    }

    &:hover {
        mix-blend-mode: difference;
    }
}

.center {
    flex: 1;
    display: none;
    justify-content: flex-end;
    align-items: center;

    &.start {
        justify-content: flex-start;
    }

    @media (min-width: 1025px) {
        display: flex;
    }
}

.menuItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 10px;

    svg {
        margin-bottom: 2px;
    }

    &:hover {
        background-color: rgba(var(--secondary-light), 0.5);
    }
}

.smallLink {
    border-radius: 8px;

    &:hover {
        background-color: rgba(var(--secondary-light), 0.5);
    }
}

.menuBorder {
    border: 1px solid var(--white);
    border-radius: 10px;
    line-height: 1.4;
    margin-left: 4px;
    text-decoration: none;
}

.menuActive {
    border-radius: 10px;
    background-color: rgb(var(--secondary-light));
    cursor: default;
}

.left {
    height: 2.75rem;
    min-width: 2.75rem;
    color: var(--text-color);
    border-radius: 14px;
    cursor: pointer;
    transition: background-color 0.25s ease;
    position: relative;

    &.login {
        height: 40px;
        padding: 0 40px;
        border-radius: 32px;
        background-color: rgb(var(--primary-main));
        font-size: 18px;
    }

    &.user {
        border: 0.25rem solid transparent;
        background-clip: content-box;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        font-weight: 700;
        align-items: flex-start;
        line-height: 1.9;
        border-radius: 50%;
        position: relative;
        background-color: rgb(var(--primary-main));
    }

    &:hover {
        &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 32px;
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    &.selected {
        background-color: rgb(var(--primary-dark));
    }
}

.betslip {
    position: absolute;
    top: calc(100% - 0.4rem);
    right: 0;
    color: var(--white);
    padding: 0.15rem 0.75rem 0.1rem;
    border-radius: 0.25rem 0 0 0.25rem;
    font-size: 0.625rem;
    line-height: 2;
    white-space: nowrap;
    text-transform: capitalize;
    transform: translate(1.5rem, 0.25rem);
    background-color: rgb(var(--green-color));
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    z-index: 106;

    @media (max-width: 480px) {
        transform: translate(0.5rem, 0.25rem);
    }

    @media (min-width: 1440px) {
        border-radius: 0.25rem;
    }

    &:hover {
        text-decoration: underline;
    }

    &.flash {
        animation: flash 0.8s;
    }
}

.headerLink {
    @media (max-width: 480px) {
        font-size: 14px;
    }
}

@keyframes flash {
    0%,50%,to {
        opacity: 1;
    }

    25%,75% {
        opacity: 0;
    }
}
