.footer {
    color: var(--text-color);
    border-top: 1px solid rgb(var(--secondary-main));
    background-color: var(--main-bg-color);

    @media (max-width: 480px) {
        padding-bottom: 0.5rem;
    }
}

.inner {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    gap: 6rem;

    @media (max-width: 480px) {
        gap: 0;
        flex-wrap: wrap;
        padding-left: 0.5rem;
        padding-left: 0.5rem;
    }
}

.logoBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
}

.trustpilot {
    background: url(/trustpilot-logo-original.svg) no-repeat;
    background-size: contain;
    background-position: center;
    text-indent: -9999px;
    width: 72px;
}

.copyBox {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
}

.copyLink {
    padding: 4px 8px;
    background-color: rgb(var(--secondary-light));
    border-radius: 4px;
    margin-left: 12px;
    transition: background-color 0.25s;

    &:hover {
        background-color: rgba(var(--secondary-light), 0.9);
    }

    &.copyLinkFirst {
        margin-left: auto;
    }

    &.copyLinkLast {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.copy {
    font-size: 14px;
    text-align: left;

    @media (max-width: 480px) {
        font-size: 12px;
        flex-grow: 1;
        white-space: nowrap;
        text-align: center;
    }
}

.logo {
    align-self: center;
    display: block;
    line-height: 1.8;
    font-size: 1rem;
    cursor: pointer;
    color: #F8EBFF;
    white-space: nowrap;
    padding: 0 0.5rem;
    background: url(/logo.png) no-repeat;
    background-size: contain;
    background-position: center;
    text-indent: -9999px;
    width: 100px;
    flex-shrink: 0;

    &:hover {
        mix-blend-mode: difference;
    }
}

.linkBlock {
    display: flex;
    flex-wrap: wrap;
    padding-left: 6rem;
    position: relative;

    @media (max-width: 480px) {
        padding-left: 3rem;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: 1rem;
        margin-left: 3rem;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 1px solid rgb(var(--secondary-main));
    }
}

.link {
    font-size: 18px;
    transition: border 0.2s ease-in;

    @media (max-width: 480px) {
        font-size: 15px;
    }

    &:hover {
        text-decoration: underline;
    }

    + .link {
        margin-left: 3rem;

        @media (max-width: 480px) {
            margin-left: 0;
        }
    }
}