.wrapper {
    min-height: calc(100vh - 160px);

    @media (max-width: 480px) {
        min-height: calc(100vh - 180px);
    }
}

.container {
    max-width: var(--main-container-max-width, var(--max-width));
    margin: 0 auto;
    position: relative;
    padding: var(--main-container-pad-t, 3rem) var(--main-container-pad-x, 1.5rem) 8rem;

    @media (max-width: 480px) {
        padding: var(--main-container-mob-pad-t, 2rem) 0.25rem 5rem;
    }
}

.notSup {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.browsers {
    display: flex;
    justify-content: center;
}

.browser {
    padding-top: 53px;
    text-transform: capitalize;

    &.chrome {
        background: url('/browser/chrome.svg') no-repeat top center;
        background-size: 44px;
    }

    &.firefox {
        background: url('/browser/firefox.png') no-repeat top center;
        background-size: 44px;
    }

    &.safari {
        background: url('/browser/safari.png') no-repeat top center;
        background-size: 44px;
    }

    &.opera {
        background: url('/browser/opera.svg') no-repeat top center;
        background-size: 44px;
    }

    + .browser {
        margin-left: 24px;
    }
}

.space {
    flex: 1;
}

.sadFace {
    width: 50%;
    max-width: 200px;
    margin: 44px auto;

    path {
        fill: rgb(var(--primary-dark));
    }
}

.desc {
    margin-left: auto;
    margin-right: auto;
}
