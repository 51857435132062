.banner {
    position: relative;
    z-index: 20;
    border-top: 2px solid rgb(var(--secondary-main));
    background-color: var(--white);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    max-height: 7.5rem;
    overflow: hidden;

    &.closed {
        transition: max-height 0.25s ease;
        max-height: 0;
    }
}

.inner {
    position: relative;
    margin: 0 auto;
    max-width: var(--max-width);
    /* background: url(https://cdn.dribbble.com/users/238864/screenshots/9190884/image.png) no-repeat center center; */
    background-clip: content-box;
    background-size: cover;
    height: 5.25rem;
    box-sizing: content-box;
}

.close {
    position: absolute;
    right: 0.25rem;
    top: 0.125rem;
    color: var(--text-color);
    cursor: pointer;

    &:hover {
        color: rgb(var(--primary-dark));
    }
}
